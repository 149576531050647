import $ from 'jquery';
import { $window } from '../utils/globals';

const $header = $('.header');
const $main = $('.main');

$('.js-toggle').on('click', function(e) {
	e.preventDefault();

    const $this = $(this);

	$this.toggleClass('is-active');

	$header.toggleClass('has-active-nav');
	$main.toggleClass('has-active-nav');

    if($this.hasClass('is-active')) {
        $('body').addClass('no-scroll');
    } else {
	   $('body').removeClass('no-scroll');
    }
})

$window.on('load resize', () => {
    if($window.width() < 768) {
        $('.header .header__nav').appendTo('.header .header__aside');
        $('.header .header__location').appendTo('.header .header__aside');
        $('.bar').insertBefore('.header .header__aside .nav-access');
    } else {
        if($header.hasClass('has-active-nav')) {
            $header.removeClass('has-active-nav');
            $main.removeClass('has-active-nav');
            $('.js-toggle').removeClass('is-active');
            $('body').removeClass('no-scroll');
        }

        $('.header .header__nav').detach().appendTo('.header > .shell');
        $('.header .header__location').detach().appendTo('.header .header__content');
		$('.bar').detach().insertBefore('.header');
    }
})

$('.header__nav a').on('click', function() {
    const $this = $(this);

    sessionStorage.setItem('active-nav-link', $this.attr('href'));
})
