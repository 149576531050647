import $ from "jquery";
import "magnific-popup";

$(window).on("load", () => {
    const detailRedeem = $(".js-detail-redeem");

    if (detailRedeem.length === 0) {
        return;
    }

    $(".js-detail-redeem").magnificPopup({
        type: "inline",
        fixedContentPos: true,
        preloader: false,
        mainClass: "mfp-login",
        closeOnBgClick: true,
        callbacks: {
            open: function () {
                $(".js-popup-close").on("click", function (event) {
                    event.preventDefault();
                    $.magnificPopup.close();
                });
            },
        },
    });
});
