import $ from 'jquery';

$(function() {
    $('.custom-radio-input input').on('change', function() {
        const $this = $(this);

        $(`.custom-radio-input input[name=${$this.attr('name')}]`).each(function() {
            $(this).parent().toggleClass('is-active', false);
        })

        $this.parent().toggleClass('is-active', $this.attr('checked'));
    })
});
