import $ from "jquery";
import { $window } from "../utils/globals";

function stickyElements(elem) {
    const stickyElem = $(elem);
    const header = $('header.header');
    $("<div/>").insertBefore(stickyElem);
    const prevElem = stickyElem.prev();

    function stickyRelocate() {
        const windowScrollTop = $window.scrollTop();
        const prevElemOffsetTop = prevElem.offset().top;

        if (windowScrollTop > prevElemOffsetTop) {
            stickyElem.addClass("is-fixed");
            prevElem.height(stickyElem.outerHeight());
            header.hide();
        } else {
            stickyElem.removeClass("is-fixed");
            prevElem.height(0);
            header.show();
        }
    }

    $window.on("scroll", stickyRelocate);
    stickyRelocate();
}

if ($(".js-sticky").length) {
    stickyElements(".js-sticky");
}
