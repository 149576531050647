/**
 * External Dependencies
 */
import $ from "jquery";
import axios from "axios";

/**
 * Internal Dependencies
 */
import route from "@/utils/route";

$(async function() {
    if (!sessionStorage.getItem("timezone")) {
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        try {
            await axios.post(route("customer.timezone.store"), {
                timezone: timezone,
            });

            sessionStorage.setItem("timezone", timezone);
        } catch (error) {
            throw error;
        }
    }
});
