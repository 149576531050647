import $ from "jquery";

$(function() {
    $("[data-client-date]").each(function() {
        const $date = $(this);
        const date = new Date($date.data("client-date"));
        const formatter = new Intl.DateTimeFormat("en-US", {
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "2-digit",
            hour12: true,
        });

        const parts = formatter.formatToParts(date);

        const dateParts = {};
        parts.forEach((part) => {
            dateParts[part.type] = part.value;
        });

        function getOrdinalSuffix(day) {
            if (day > 3 && day < 21) return "th";
            switch (day % 10) {
                case 1:
                    return "st";
                case 2:
                    return "nd";
                case 3:
                    return "rd";
                default:
                    return "th";
            }
        }

        const dayWithSuffix = dateParts.day + getOrdinalSuffix(dateParts.day);

        $date.text(
            `${dateParts.month} ${dayWithSuffix} ${dateParts.hour}:${dateParts.minute}${dateParts.dayPeriod.toLowerCase()}`,
        );
    });
});
