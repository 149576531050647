import $ from 'jquery';
import { $window } from "../utils/globals";
import Swiper from "swiper/bundle";

$(".js-slider .slider__slide").each(function (i) {
    $(this).on("click", function (event) {
        event.preventDefault();

        $(this).closest(".js-slider").addClass("is-active");

        if ($window.width() < 575) {
            $("html,body").animate({
                scrollTop: $(".section-gallery").offset().top - 154,
            });
        }

        const swiper = new Swiper(".js-slider .swiper", {
            slidesPerView: 1,
            speed: 400,
            navigation: {
                nextEl: ".js-slider-next",
                prevEl: ".js-slider-prev",
            },
        });

        swiper.slideTo(i, 0);

        $(".js-slider-close").on("click", function (e) {
            e.preventDefault();

            $(this).closest(".js-slider").removeClass("is-active");

            swiper.destroy();
        });
    });
});

if (document.querySelector(".js-slider-thumbs")) {
    // Thumbs
    const swiperThumbs = new Swiper(".js-slider-thumbs .swiper", {
        spaceBetween: 17,
        slidesPerView: 4,
        freeMode: true,
        watchSlidesProgress: true,
        slideToClickedSlide: true,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });

    const swiper = new Swiper(".js-slider-product .swiper", {
        autoHeight: true,
        spaceBetween: 10,
        thumbs: {
            swiper: swiperThumbs,
        },
        controller: {
            control: swiperThumbs,
        }
    });

    // swiper.controller.control = swiperThumbs;
}
