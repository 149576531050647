/**
 * External dependencies:
 */
import axios from 'axios';
import { get } from 'lodash';
import $ from 'jquery';

/**
 * Internal dependencies:
 */
import updateCurrentBid from "@/frontend/domain/auction/update-current-bid";

$('.js-form-bid').on('submit', function(event) {
    event.preventDefault();
    const defaultErrorMessage = 'Something went wrong.';
    const $form = $(this);
    const amount = $form.find('[name=amount]').val();
    const $out = $form.find('.out');
    const outSuccess = () => {
        $out.text('Bid successful.');
        $out.removeClass('error');
    };

    const outError = (message) => {
        $out.text(message);
        $out.addClass('error');
    };

    axios.post($form.prop('action'), { amount })
        .then((response) => {
            const success = get(response, 'data.success', false);
            if (success) {
                outSuccess();
                updateCurrentBid();
            } else {
                outError(get(response, 'data.message', defaultErrorMessage));
            }
        })
        .catch(() => {
            outError(defaultErrorMessage)
        });
});
