import $ from 'jquery';

$('.js-form-login').on('submit', function(e) {
    e.preventDefault();

    const $submit = $(this).find('.form__actions input[type=submit]');
    const $error = $(this).find('.form__error');
    $submit.prop('disabled', true);

    const showError = (message) => {
        $error.text(message);
        $error.removeClass('hidden');
    };

    const hideError = () => {
        $error.addClass('hidden');
    };

    $.ajax({
        method: 'POST',
        url: $(this).prop('action'),
        data: $(this).serializeArray(),
    })
        .done((res) => {
            if (!res.success) {
                const message = res.error ?? 'Unknown error';
                return showError(message);
            }

            hideError();
            const params = new URLSearchParams(window.location.search);
            if (params.has('return_url')) {
                const url = new URL(params.get('return_url'));
                if (url.hostname === window.location.hostname) {
                    window.location.href = url.href;
                } else {
                    window.location.reload();
                }
            } else {
                window.location.reload();
            }
        })
        .fail((err) => {
            showError('Unknown error');
        })
        .always(() => {
            $submit.prop('disabled', false);
        });
});

$('.js-form-register').on('submit', function(e) {
    e.preventDefault();

    const $this = $(this);
    const $submit = $this.find('.form__actions input[type=submit]');
    const $error = $this.find('.js-register-error');
    $submit.prop('disabled', true);

    const showErrors = (response) => {
        const errors = response?.errors ?? {};
        const message = response?.message ?? 'Unknown error';

        const errorKeys = Object.keys(errors);
        if (!errorKeys.length) {
            $error.text(message);
            $error.removeClass('hidden');
        }

        errorKeys.forEach((errorKey) => {
            const error = errors[errorKey][0];
            const $holder = $this.find(`input[name=${errorKey}]`).closest('.form__row').find('.form__error');
            $holder.text(error);
            $holder.removeClass('hidden');
        });
    };

    const hideErrors = () => {
        $this.find('.form__error').addClass('hidden');
    };

    hideErrors();

    $.ajax({
        method: 'POST',
        url: $(this).prop('action'),
        data: $(this).serializeArray(),
    })
        .done((res) => {
            if (!res.success) {
                return showErrors({});
            }

            document.location.reload();
        })
        .fail((err) => {
            showErrors(err.responseJSON);
        })
        .always(() => {
            $submit.prop('disabled', false);
        });
});

(() => {
    let loading = false;

    $('.js-form-forgot').on('submit', function (e) {
        e.preventDefault();

        if (loading) {
            return;
        }

        loading = true;

        const $this = $(this);
        const $error = $('.js-forgot-error');
        const $success = $('.js-forgot-success');
        const $loader = $('.js-loader');

        $error.addClass('hidden');
        $success.addClass('hidden');
        $loader.removeClass('hidden');

        $.ajax({
            method: $this.prop('method'),
            url: $this.prop('action'),
            data: $this.serializeArray(),
        })
            .done((response) => {
                const success = response?.success ?? false;
                if (success) {
                    $error.addClass('hidden');

                    $success.text(response?.message ?? 'Pasword reset link sent successfully.');
                    $success.removeClass('hidden');
                } else {
                    const message = response?.message ?? 'Unknown error';
                    $error.text(message);
                    $error.removeClass('hidden');
                }
            })
            .fail(() => {
                $error.text('Unknown error');
                $error.removeClass('hidden');
            })
            .always(() => {
                loading = false;
                $loader.addClass('hidden');
            });
    });
})();

