import $ from 'jquery';

const $promoFixed = $('.promo--fixed');

if($promoFixed.length) {
	$(window).on('load scroll', function() {
		const footerToTop = $('.footer').position().top;
		const scrollTop = $(document).scrollTop() + $(window).height();
		const difference = scrollTop - footerToTop;
		const bottomValue = scrollTop > footerToTop ? difference : 0;
		$promoFixed.css('bottom', bottomValue);
	})
}

$('.promo--fixed .promo__close').on('click', function(event) {
	event.preventDefault();
	$(this).closest('.promo').hide();
})
