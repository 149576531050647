import $ from 'jquery';

$('.js-list-toggle').on('click', function(event) {
	event.preventDefault();
	const $this = $(this);
	const $parent = $this.closest('.section');

	$parent.toggleClass('is-active');

	if($parent.hasClass('is-active')) {
		$('.btn__map').addClass('is-active');
		$('.btn__list').removeClass('is-active');
		$this.find('span').html('Map View');
	} else {
		$('.btn__map').removeClass('is-active');
		$('.btn__list').addClass('is-active');
		$this.find('span').html('List View');
	}
})
