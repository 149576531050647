import $ from "jquery";

$("[data-role=toggle-password]").on("click", function (event) {
    event.preventDefault();

    const $this = $(this);
    const $icon = $this.children().first();

    $icon.toggleClass("fa-eye fa-eye-slash");

    const type = $icon.hasClass("fa-eye-slash") ? "text" : "password";

    $this.parent().find("input").attr("type", type);
});
