import $ from 'jquery';
import { $window } from '../utils/globals';

$window.on('load resize', () => {
    if($window.width() < 768) {
       $('.filters').appendTo('.section .section__head');
    } else {
    	$('.filters').detach().appendTo('.section .section__aside');
    }
})

$('.js-filter').on('click', function(event) {
	event.preventDefault();

	const $this = $(this);

	$('.filters').addClass('is-active');

	$('body').addClass('no-scroll');
})

$('.js-filter-close').on('click', function(event) {
	event.preventDefault();

	const $this = $(this);

	$('.filters').removeClass('is-active');
	$('body').removeClass('no-scroll');
})
